













// Import vendors ----------------------------------------------------------------------------------
import { computed, ComputedRef, defineComponent } from '@vue/composition-api';
import { without } from 'lodash';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModule, usePodocoreModuleService } from '@/plugins/podocore';
// Import utils ------------------------------------------------------------------------------------
import { useRoute } from '@/utils/router.utils';
// Import components -------------------------------------------------------------------------------
import NavbarItem from '@/components/nav/NavbarItem.vue';
// Import types ------------------------------------------------------------------------------------
import type { NavItem } from '@/components/nav/NavbarItem.vue';
import {
  AbilityAction,
  AbilitySubject
} from '@digitsole/blackburn-entities/dist/entities/ability/types/ability.enums';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'SettingsNavbar',
  components: {
    NavbarItem
  },
  setup(_, { root }) {
    const route = useRoute();
    const aclModule = usePodocoreModule('acl');
    const workspacesService = usePodocoreModuleService('workspaces');
    const doctorService = usePodocoreModuleService('doctor');
    const aclService = usePodocoreModuleService('acl');

    const doctor = computed(() => {
      return (doctorService.state.value as any).context.doctor;
    });

    const currentWorkspace = computed(() => {
      return (workspacesService.state.value as any).context.current;
    });

    const remainingFreeUserSlots = computed(() => {
      return (
        aclModule.reactiveBuild.relevantRuleFor(AbilityAction.Invite, AbilitySubject.Workspace)?.conditions
          ?.remainingFreeUserSlots ?? 0
      );
    });

    const canMigrate = computed(() => (aclService.state.value as any).context.features?.migration === true);

    const navItems: ComputedRef<NavItem[]> = computed(
      () =>
        without(
          [
            {
              title: root.$tc('settings.navbar.preference', 2),
              hash: '#preferences',
              isSelected: route.hash.value === '#preferences' || !route.hash.value,
              to: {
                name: 'settings',
                hash: '#preferences'
              }
            },
            {
              title: root.$t('settings.navbar.workspace'),
              hash: '#workspace',
              isSelected: !!route.hash.value?.startsWith('#workspace'),
              to: {
                name: 'settings',
                hash: '#workspace-general'
              }
            },
            canMigrate.value
              ? {
                  title: root.$t('podomigration.title'),
                  hash: '#preferences',
                  isSelected: route.hash.value === '#podomigration',
                  to: {
                    name: 'settings',
                    hash: '#podomigration'
                  }
                }
              : undefined
          ],
          undefined
        ) as NavItem[]
    );

    return {
      // Values
      navItems,
      route,
      remainingFreeUserSlots,
      currentWorkspace
    };
  }
});
