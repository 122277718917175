































































// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent } from '@vue/composition-api';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModule, usePodocoreModuleService } from '@/plugins/podocore';
// Import components -------------------------------------------------------------------------------
import CompositeCard from '@/components/composite/CompositeCard.vue';
import AlertError from '@/components/alerts/AlertError.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'SettingsModules',
  components: { /* AlertError,  */ CompositeCard },
  setup() {
    const doctorService = usePodocoreModuleService('doctor');

    const doctor = computed(() => {
      return (doctorService.state.value as any).context.doctor;
    });

    const host = 'podosmart-production.auth.eu-central-1.amazoncognito.com';
    const clientId = '79vj6cf6u6ia0dglho7cqs22pp';

    const url = new URL(`https://${host}`);

    url.pathname = '/login';
    url.searchParams.set('client_id', clientId);
    url.searchParams.set('response_type', 'token');
    url.searchParams.set('scope', 'openid');
    url.searchParams.set('redirect_uri', `${window.location.origin}/podomigration`);

    const isConnected = computed(() => {
      return doctor.value.podomigration?.cognitoId;
    });

    return {
      url,
      isConnected,
      doctor
    };
  }
});
