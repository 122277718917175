

























// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed } from '@vue/composition-api';
// Import plugins ----------------------------------------------------------------------------------
import i18n from '@/plugins/i18n';
// Import utils ------------------------------------------------------------------------------------
import { usePodocoreModule, usePodocoreModuleService } from '@/plugins/podocore';
import { useVuetifyBreakpoints } from '@/utils/vuetify-breakpoints.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'SettingsWorkspaceFeatures',
  setup() {
    const aclService = usePodocoreModuleService('acl');
    const busModule = usePodocoreModule('bus');

    const { isMdAndLarger } = useVuetifyBreakpoints();

    const aclFeaturesInformation: any = {
      running: {
        desc: i18n.t('commons.sentences.features.running.desc')
      },
      video: {
        desc: i18n.t('commons.sentences.features.video.desc'),
        infos: i18n.t('commons.sentences.features.rehab.infos.out-of-medical-device')
      },
      walking: {
        desc: i18n.t('commons.sentences.features.walking.desc')
      },
      rehab: {
        desc: i18n.t('commons.sentences.features.rehab.desc'),
        infos: i18n.t('commons.sentences.features.rehab.infos.out-of-medical-device')
      },
      vlr: {
        desc: i18n.t('commons.sentences.features.vlr.desc'),
        infos: i18n.t('commons.sentences.features.rehab.infos.out-of-medical-device')
      },
      migration: {
        desc: i18n.t('commons.sentences.features.migration.desc'),
        infos: i18n.t('commons.sentences.features.rehab.infos.out-of-medical-device')
      },
      orthotics: {
        desc: i18n.t('commons.sentences.features.orthotics.desc'),
        infos: i18n.t('commons.sentences.features.rehab.infos.out-of-medical-device')
      }
    };

    const aclFeatures = computed(() => {
      const _features = (aclService.state.value as any).context.features;

      const reorderedFeatures: any = {
        walking: !!_features?.walking,
        running: !!_features?.running,
        orthotics: !!_features?.orthotics,
        vlr: !!_features?.vlr,
        migration: !!_features?.migration,
        rehab: !!_features?.rehab,
        video: !!_features?.video
      };

      const features = Object.entries(reorderedFeatures).filter(([k]) =>
        Object.keys(aclFeaturesInformation).includes(k)
      );

      return Object.fromEntries(features);
    });

    const keyTitle = {
      running: 'commons.presentationModules.selectModules.run.title',
      rehab: 'commons.presentationModules.selectModules.rehab.title',
      video: 'commons.word.video',
      walking: 'commons.presentationModules.selectModules.walk.title',
      vlr: 'commons.presentationModules.selectModules.vlr.title',
      migration: 'commons.presentationModules.selectModules.migration.title',
      orthotics: 'commons.presentationModules.selectModules.orthotics.title'
    };

    busModule.useEventSubscriber(busModule.events.workspaceMembersInvited, (event) => {
      aclService.send({
        type: 'CHECK'
      });
    });

    busModule.useEventSubscriber(busModule.events.workspaceMembersDeleted, (event) => {
      aclService.send({
        type: 'CHECK'
      });
    });

    return {
      // Values
      keyTitle,
      aclFeatures,
      aclFeaturesInformation,
      isMdAndLarger
    };
  }
});
