




































// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, watch } from '@vue/composition-api';
// Import utils ------------------------------------------------------------------------------------
import { usePodocoreModuleService } from '@/plugins/podocore';
import { SupportedLang } from '@/utils/i18n.utils';
import { useForm, useFormFieldAutoComplete, useFormObserver } from '@/utils/forms.utils';
import { useAnalytics } from '@/utils/analytics.utils';
// Import components -------------------------------------------------------------------------------
import FormFieldSelect from '@/components/forms/FormFieldSelect.vue';
import AlertError from '@/components/alerts/AlertError.vue';
import CountryFlag from '@/components/CountryFlag.vue';
// Import types ------------------------------------------------------------------------------------
import type { Config, ConfigLang } from '@/plugins/podocore/repositories/config.repository';
// Export / declare types --------------------------------------------------------------------------
type TLang = {
  text: string;
  value: SupportedLang;
  overrideFlag?: string;
  disabled?: boolean;
};
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'SettingsLanguage',
  components: { AlertError, CountryFlag, FormFieldSelect },
  setup() {
    const rObserver = useFormObserver();

    const { trackSuccess, trackFailure } = useAnalytics();

    const configService = usePodocoreModuleService('config');
    const doctorService = usePodocoreModuleService('doctor');

    const langConfig = (configService.state.value.context as { config: Config }).config.lang;
    const l =
      (langConfig[process.env.BLACKBURN__STAGE as keyof ConfigLang] as SupportedLang[]) ??
      Object.values(SupportedLang);

    const doctorConfigLang = computed(() => {
      const dl = (doctorService.state.value as any).context.doctor.config.lang;
      return l.includes(dl) ? dl : SupportedLang.En;
    });

    // List of supported languages
    const supportedLangs: TLang[] = [
      {
        text: 'English',
        value: SupportedLang.En
      },
      {
        text: 'Français',
        value: SupportedLang.Fr
      },
      {
        text: 'Español',
        value: SupportedLang.Es
      },
      {
        text: 'Polski',
        value: SupportedLang.Pl
      },
      {
        text: 'Danish',
        value: SupportedLang.Da,
        overrideFlag: 'dk'
      },
      {
        text: 'Norsk',
        value: SupportedLang.No
      },
      {
        text: 'Ελληνικά',
        value: SupportedLang.El,
        overrideFlag: 'gr'
      },
      {
        text: 'Magyar nyelv',
        value: SupportedLang.Hu
      },
      {
        text: 'Български',
        value: SupportedLang.Bg
      },
      {
        text: 'Čeština',
        value: SupportedLang.Cz
      },
      {
        text: '한국어',
        value: SupportedLang.Kr
      }
      /* {
        text: 'Deutsch',
        value: SupportedLang.De
      },

      {
        text: 'Italiano',
        value: SupportedLang.It
      },

      {
        text: 'Português',
        value: SupportedLang.Pt
      },
      {
        text: 'Suomi',
        value: SupportedLang.Fi
      } */
    ].filter((d) => {
      if (l.includes(d.value)) {
        return true;
      }

      return false;
    });

    const lang = useFormFieldAutoComplete<SupportedLang, TLang>({
      key: 'lang',
      value: doctorConfigLang.value,
      items: supportedLangs,
      rules: {
        required: true
      }
    });

    // Watch "lang" and execute "submit" when a mutation occurs
    watch(
      () => lang.value,
      () => {
        if (doctorConfigLang.value !== lang.value) {
          submit();
        }
      }
    );

    watch(doctorService.state, (currentState, previousState) => {
      // If an error occurs, replace "lang" by previous value & send failure event
      if (
        previousState.matches({ patching: { config: 'processing' } }) &&
        currentState.matches({
          patching: { config: 'failure' }
        })
      ) {
        lang.value = doctorConfigLang.value;
        trackFailure('Settings/Languages', 'Language save failed');
      }
      // If success, send success event
      if (
        previousState.matches({ patching: { config: 'processing' } }) &&
        currentState.matches({
          patching: { config: 'success' }
        })
      ) {
        trackSuccess('Settings/Languages', supportedLangs.find((x: TLang) => x.value === lang.value)!.text);
      }
    });

    // Form manager
    const { submit } = useForm({ lang }, rObserver, (fields) => {
      doctorService.send({
        type: 'PATCH_CONFIG',
        data: {
          lang: fields.lang.value
        }
      } as any);
    });

    const isSucceeded = computed(() =>
      [{ patching: { config: 'success' } }].some((element) => doctorService.state.value.matches(element))
    );

    const isErrored = computed(() =>
      [{ patching: { config: 'failure' } }].some((element) => doctorService.state.value.matches(element))
    );
    const isDisabled = computed(
      () =>
        ![{ patching: { config: 'success' } }, { patching: { config: 'failure' } }, 'success'].some(
          (element) => doctorService.state.value.matches(element)
        )
    );

    const isLoading = computed(() =>
      [{ patching: { config: 'processing' } }].some((element) => doctorService.state.value.matches(element))
    );

    return {
      // Form observer
      rObserver,
      // Services
      doctorService,
      // Values
      supportedLangs,
      // Form fields
      lang,
      // Flags
      isSucceeded,
      isErrored,
      isDisabled,
      isLoading
    };
  }
});
