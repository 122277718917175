
























// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed } from '@vue/composition-api';
// Import utils ------------------------------------------------------------------------------------
import { usePodocoreModuleService } from '@/plugins/podocore';
import { useRoute } from '@/utils/router.utils';
import { useSidebar } from '@/utils/sidebar.utils';
// Import components -------------------------------------------------------------------------------
import SettingsPreferences from '@/components/settings/SettingsPreferences.vue';
import SettingsNavbar from '@/components/settings/SettingsNavbar.vue';
import SettingsWorkspaceGeneral from '@/components/settings/SettingsWorkspaceGeneral.vue';
import SettingsPodomigration from '@/components/settings/SettingsPodomigration.vue';
// Import dialogs ----------------------------------------------------------------------------------
import DialogWorkspaceMemberInvite from '@/components/dialogs/DialogWorkspaceMemberInvite.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'PageSettings',
  components: {
    SettingsPreferences,
    SettingsWorkspaceGeneral,
    SettingsPodomigration,
    SettingsNavbar,
    DialogWorkspaceMemberInvite
  },
  setup() {
    const workspacesService = usePodocoreModuleService('workspaces');
    const { hash } = useRoute();
    const { patientSidebarMiniVariant } = useSidebar();

    const currentWorkspace = computed(() => {
      return (workspacesService.state.value as any).context.current;
    });

    return {
      patientSidebarMiniVariant,
      hash,
      workspacesService,
      currentWorkspace
    };
  }
});
