





































































































































































































// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed, ref, watch } from '@vue/composition-api';
import { capitalize } from 'lodash';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModule, usePodocoreModuleService } from '@/plugins/podocore';
import i18n from '@/plugins/i18n';
// Import config -----------------------------------------------------------------------------------
import { apiConfig } from '@/config/api.config';
// Import types ------------------------------------------------------------------------------------
import {
  AbilityAction,
  AbilitySubject
} from '@digitsole/blackburn-entities/dist/entities/ability/types/ability.enums';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'SettingsWorkspaceMembers',
  props: {
    workspacesService: {
      type: Object,
      required: true
    }
  },
  setup(properties) {
    const aclModule = usePodocoreModule('acl');
    const requestModule = usePodocoreModule('request');
    const doctorService = usePodocoreModuleService('doctor');

    const doctor = computed(() => {
      return (doctorService.state.value as any).context.doctor;
    });

    const currentWorkspace = computed(() => {
      return (properties.workspacesService.state.value as any).context.current;
    });

    const doctorRole = computed(() => {
      return currentWorkspace.value.members.find((member: any) => member.cuid === doctor.value.cuid).role;
    });

    const remainingFreeUserSlots = computed(() => {
      return (
        aclModule.reactiveBuild.relevantRuleFor(AbilityAction.Invite, AbilitySubject.Workspace)?.conditions
          ?.remainingFreeUserSlots ?? 0
      );
    });

    const owner = computed(() => currentWorkspace.value.members.find((x: any) => x.role === 'owner'));
    const members = computed(() => currentWorkspace.value.members.filter((x: any) => x.role !== 'owner'));

    const busModule = usePodocoreModule('bus');

    const isSended = ref(false);

    const deleteMemberRequest: any = ref(null);
    function deleteMember(memberCuid: string, isInvited: boolean) {
      if (isPending.value) return;

      deleteMemberRequest.value = requestModule.useAuthenticatedRequest(
        `${apiConfig.default}/workspaces/${currentWorkspace.value.cuid}/members/${memberCuid}?isInvited=${isInvited}`,
        {
          axios: {
            method: 'DELETE'
          }
        }
      );
      deleteMemberRequest.value?.request();
    }

    const isPending = computed(() => deleteMemberRequest.value?.isPending.value);

    const isSuccessDeletion = computed(() => {
      return !deleteMemberRequest.value?.error && !!deleteMemberRequest.value?.data;
    });
    const isSuccessSendInvitation = computed(() => {
      return !workspaceInviteMembersRequest.value?.error.value;
    });

    const workspaceInviteMembersRequest = computed(() =>
      requestModule.useAuthenticatedRequest(
        `${apiConfig.default}/workspaces/${currentWorkspace.value.cuid}/members/invite`,
        {
          axios: {
            method: 'POST'
          }
        }
      )
    );

    function inviteWorkspaceMembers(cuid: string) {
      const redirect_uri = new URL(window.location.origin);

      redirect_uri.pathname = '/join-workspace';

      workspaceInviteMembersRequest.value.request({
        axios: {
          method: 'POST',
          data: {
            doctorCuid: cuid,
            redirectUrl: redirect_uri.href
          }
        }
      });

      isSended.value = true;
    }

    function resendInviteWorkspaceMembers(cuid: string) {
      inviteWorkspaceMembers(cuid);
    }

    watch(isSuccessDeletion, (value) => {
      if (value) {
        busModule.publish(
          busModule.events.workspaceMembersDeleted({
            workspaceCuid: currentWorkspace.value.cuid
          })
        );
      }
    });

    watch(
      isSuccessSendInvitation,
      (value) => {
        if (value && isSended.value) {
          // Notification
          busModule.publish(
            busModule.events.createNotification({
              message: {
                body: {
                  message: {
                    messageType: 'returned-invitation',
                    data: {
                      icon: 'check-circle',
                      custom: i18n.t('workspace.invite-new-member.sent')
                    }
                  }
                },
                type: 'returned-invitation'
              }
            })
          );
          isSended.value = false;
        }
      },
      { deep: true }
    );

    busModule.useEventSubscriber(busModule.events.workspaceMembersInvited, (event) => {
      properties.workspacesService.send({
        type: 'FETCH',
        data: {
          workspaceCuid: event.payload.workspaceCuid
        }
      } as any);
    });

    busModule.useEventSubscriber(busModule.events.workspaceMembersDeleted, (event) => {
      properties.workspacesService.send({
        type: 'FETCH',
        data: {
          workspaceCuid: event.payload.workspaceCuid
        }
      } as any);
    });

    function openInviteDialog() {
      busModule.publish(
        busModule.events.openDialog({
          id: 'workspace-invite'
        })
      );
    }

    return {
      // Values
      doctor,
      doctorRole,
      currentWorkspace,
      remainingFreeUserSlots,
      isPending,
      owner,
      members,
      // Functions
      capitalize,
      deleteMember,
      inviteWorkspaceMembers,
      resendInviteWorkspaceMembers,
      openInviteDialog
    };
  }
});
