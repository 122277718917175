

























































// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, watch, computed } from '@vue/composition-api';
// Import utils ------------------------------------------------------------------------------------
import { useForm, useFormObserver, useFormFieldAutoComplete } from '@/utils/forms.utils';
import { useNotifications, TSound } from '@/utils/notifications.utils';
import { useAnalytics } from '@/utils/analytics.utils';
// Import components -------------------------------------------------------------------------------
import FormFieldSelect from '../forms/FormFieldSelect.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'SettingsNotifications',
  components: { FormFieldSelect },
  setup() {
    const { sounds, config, playing, play } = useNotifications();

    const rObserver = useFormObserver();

    const { trackSuccess } = useAnalytics();

    const notification = useFormFieldAutoComplete<string, TSound>({
      key: 'notification',
      value: config.value.sound,
      items: sounds,
      rules: {
        required: true
      }
    });

    // Form manager
    const { submit, reset } = useForm({ notification }, rObserver, () => {
      config.value.sound = notification.value as string;
    });

    const volumeIcon = computed(() => {
      if (config.value.muted) return 'volume-xmark';
      if (config.value.volume > 70) {
        return 'volume-high';
      } else if (config.value.volume > 40) {
        return 'volume';
      } else if (config.value.volume > 10) {
        return 'volume-low';
      } else if (config.value.volume > 0) {
        return 'volume-off';
      } else {
        return 'volume-xmark';
      }
    });

    function toggleMute() {
      config.value.muted = !config.value.muted;
    }

    watch(
      () => notification.value,
      () => {
        submit();
        trackSuccess('Settings/Notifications', notification.value);
        setTimeout(() => {
          play();
          reset();
        });
      }
    );

    return {
      // Form observer
      rObserver,
      // Form fields
      notification,
      // Actions
      submit,
      play,
      toggleMute,
      // Values
      config,
      playing,
      volumeIcon
    };
  }
});
