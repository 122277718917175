import i18n from '@/plugins/i18n';
import { usePodocoreModule } from '@/plugins/podocore';
import router from '@/plugins/router';
import { MediaType, useCDN } from '@/utils/cdn.utils';
import type { AnalysisEntityCompleted } from '@digitsole/blackburn-entities/dist/entities/analysis/analysis-completed.entity';
import { computed, reactive, ref, watch } from '@vue/composition-api';
import { useLocalStorage, useTimestamp } from '@vueuse/core';
import { has, isNil } from 'lodash';
import moment from 'moment';

export type TSound = {
  label: string;
  name: string;
  url: string;
};

type NotificationDataCustom = { custom: any };

type NotificationData =
  | Pick<AnalysisEntityCompleted, 'cuid' | 'patientCuid' | 'podomigration'>
  | NotificationDataCustom;

interface Notification {
  id: string;
  type: 'analysis-completed' | 'analysis-errored' | 'maintenance' | 'custom' | 'returned-invitation';
  date: Date;
  data?: NotificationData;
  popped: boolean;
  disappear?: Date;
  time?: number;
}

const { cdn } = useCDN();

const { timestamp } = useTimestamp({ offset: 0 });

/** Configuration */
const config = useLocalStorage('notifications_v2', {
  sound: 'notification_1',
  volume: 60,
  muted: false
});

function randomId(): string {
  const uint32 = window.crypto.getRandomValues(new Uint32Array(1))[0];
  return uint32.toString(16);
}

const store = reactive<{ notifications: Notification[] }>({
  notifications: [
    /* {
      id: randomId(),
      type: 'analysis-errored',
      date: new Date(),
      data: {
        cuid: 'XXX',
        patientCuid: 'XXX',
        podomigration: {
          acquisitionId: 'cc' as any
        }
      },
      popped: true
      // disappear: new Date(new Date().getTime() + 100000),
      // time: 100000
    } */
  ]
});

/** Currently playing a sound */
const playing = ref(false);

/** Available sounds */
const sounds: TSound[] = [
  {
    label: 'Notification 1',
    name: 'notification_1',
    url: cdn(MediaType.Audio, 'notification_1.mp3', 'mgIeOqEaUbfQrEl8R_IA7VQZq.MtTqIH')
  },
  {
    label: 'Notification 2',
    name: 'notification_2',
    url: cdn(MediaType.Audio, 'notification_2.mp3', 's4aQNYZP5KGZEK.TSQveyJtlLR9g199W')
  },
  {
    label: 'Notification 3',
    name: 'notification_3',
    url: cdn(MediaType.Audio, 'notification_3.mp3', 'Mdx.qOnNFG2f903oHdVnn7yGBWBrmy5q')
  },
  {
    label: 'Notification 4',
    name: 'notification_4',
    url: cdn(MediaType.Audio, 'notification_4.mp3', '1.FcdnBKHEdDZ1EwetHTQrd3qirYlrC8')
  },
  {
    label: 'Notification 5',
    name: 'notification_5',
    url: cdn(MediaType.Audio, 'notification_5.mp3', 'VphnGqz5AjZZozrqzYqxlJC6OA5v12ZZ')
  },
  {
    label: 'Notification 6',
    name: 'notification_6',
    url: cdn(MediaType.Audio, 'notification_6.mp3', 'gIithYjb1biDDWyGtVd7obJOvHLC.234')
  },
  {
    label: 'Notification 7',
    name: 'notification_7',
    url: cdn(MediaType.Audio, 'notification_7.mp3', '1OVF6vmkYRLRB_2WoA07ekE6hJ0_B1Dl')
  },
  {
    label: 'Notification 8',
    name: 'notification_8',
    url: cdn(MediaType.Audio, 'notification_8.mp3', '1NrPeLoL.tYPzJQhwKrpgysx.WQdS50h')
  }
];

/** The sound actually configured */
const currentSound = computed(() => {
  const sound = sounds.find((sound) => sound.name === config.value.sound);
  if (!sound) {
    console.error(new Error(`Sound "${config.value.sound}" doesn't exist`));
    return sounds[0];
  } else {
    return sound;
  }
});

/** Play a sound */
function play() {
  if (playing.value) console.warn('Sound already in playback, playing it anyway');

  if (config.value.muted) return;

  playing.value = true;

  const audio = new Audio(currentSound.value.url);
  audio.volume = config.value.volume / 100;
  audio.play();

  audio.addEventListener('ended', function () {
    playing.value = false;
  });
}

function getNotificationById(notificationId: Notification['id']): Notification | undefined;
function getNotificationById(notificationId: Notification['id'], throwIfUndefined: true): Notification;
function getNotificationById(
  notificationId: Notification['id'],
  throwIfUndefined?: true
): Notification | undefined {
  const notification = store.notifications.find((notification) => notification.id === notificationId);
  if (throwIfUndefined && isNil(notification)) throw new Error("Notification doesn't exists");
  return notification as any;
}

/** Delete a notification locally */
function deleteNotification(notificationId: Notification['id']): void {
  const notification = getNotificationById(notificationId, true);
  const notificationIndex = store.notifications.findIndex((n) => n.id === notification.id);
  store.notifications.splice(notificationIndex, 1);
}

/** Delete all notifications */
function deleteNotifications(): void {
  store.notifications.splice(0);
}

/** Hide a notification */
function hideNotification(notificationId: Notification['id']): void {
  const notification = getNotificationById(notificationId, true);
  notification.popped = false;
}

/** Hide all notifications */
function hideNotifications(): void {
  store.notifications
    .filter((notification) => 'disappear' in notification && notification.popped)
    .forEach((notification) => (notification.popped = false));
}

/** Action when a notification is clicked */
function clickNotification(notificationId: Notification['id']): void {
  const notification = getNotificationById(notificationId, true);
  switch (notification.type) {
    case 'analysis-completed':
    case 'analysis-errored':
      if ('custom' in notification.data! || notification.type === 'analysis-errored') {
        deleteNotification(notification.id);
      } else {
        router.push({
          path: `/patients/${notification.data!.patientCuid}/result/${notification.data!.cuid}`
        });
        setTimeout(() => {
          deleteNotification(notification.id);
        }, 200);
      }
      break;
  }
}

watch(timestamp, (value) => {
  store.notifications
    .filter((notification) => 'disappear' in notification && notification.popped)
    .forEach((notification) => {
      const diff = notification.disappear!.getTime() - value;
      if (diff < 0) {
        hideNotification(notification.id);
      }
    });
});

export function createNotifications(): void {
  const busModule = usePodocoreModule('bus');

  busModule.useEventSubscriber(busModule.events.createNotification, ({ payload }) => {
    const message = payload.message;

    if (has(message, 'body.message.messageType')) {
      switch (message.body.message.messageType) {
        case 'analysis-completed':
          store.notifications.push({
            id: randomId(),
            type: 'analysis-completed',
            date: new Date(),
            data: message.body.message.data,
            popped: true,
            disappear: new Date(new Date().getTime() + 20000),
            time: 20000
          });
          if (!('podomigration' in message.body.message.data)) play();
          break;
        case 'analysis-errored':
          store.notifications.push({
            id: randomId(),
            type: 'analysis-errored',
            date: new Date(),
            data: message.body.message.data,
            popped: true,
            disappear: new Date(new Date().getTime() + 50000),
            time: 50000
          });
          if (!('podomigration' in message.body.message.data)) play();
          break;
        case 'returned-invitation':
          store.notifications.push({
            id: randomId(),
            type: 'returned-invitation',
            date: new Date(),
            data: message.body.message.data,
            popped: true,
            disappear: new Date(new Date().getTime() + 10000),
            time: 10000
          });
          play();
          break;
        default:
          console.warn(`skip unknown message type : "${message.body.message.messageType}"`);
          break;
      }

      if (store.notifications.length > 10) store.notifications.shift();
    } else if (message.type === 'incomingMaintenance') {
      const maintenanceDuration = moment.utc(message.end.diff(message.start)).format('HH:mm');
      store.notifications.push({
        id: randomId(),
        type: 'maintenance',
        date: new Date(),
        data: {
          custom: `${i18n.t('notifications.maintenance-will-come-on', {
            date: moment(message.start).format('LLL')
          })}. ${i18n.t('notifications.services-will-be-offline-during-aroud', {
            time: maintenanceDuration
          })} h.`
        },
        popped: true,
        disappear: new Date(new Date().getTime() + 30000),
        time: 30000
      });
    } else if (message.type === 'custom') {
      store.notifications.push({
        id: randomId(),
        type: 'custom',
        date: new Date(),
        data: message.body,
        popped: true,
        disappear: new Date(new Date().getTime() + 20000),
        time: 20000
      });
    }
  });
}

/** Notifications utils */
export function useNotifications() {
  return {
    // Values
    sounds,
    config,
    playing,
    currentSound,
    notifications: computed(() => store.notifications),
    // Methods
    play,
    clickNotification,
    deleteNotification,
    deleteNotifications,
    hideNotifications
  };
}
