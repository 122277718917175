var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{attrs:{"rules":_vm.f.rules,"name":_vm.f.key,"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var errors = ref.errors;
var valid = ref.valid;
var changed = ref.changed;
return [_c('VFileInput',_vm._g(_vm._b({ref:"rFileInput",class:['form__field__file', { 'd-none': _vm.hasLabelSlot }],attrs:{"type":_vm.f.type || 'file',"success":valid && changed,"error-messages":errors,"hide-details":'persistent-hint' in _vm.$attrs ? false : !errors || !errors.length,"prepend-icon":null},on:{"change":function ($event) {
        validate($event);
        _vm.setValue($event);
      }},scopedSlots:_vm._u([(_vm.hasProgressSlot)?{key:"progress",fn:function(){return [_vm._t("progress")]},proxy:true}:null,{key:"append",fn:function(){return [_vm._t("append")]},proxy:true}],null,true)},'VFileInput',Object.assign({}, _vm.f.attributes, _vm.$attrs),false),_vm.$listeners)),(_vm.hasLabelSlot)?[_vm._t("label")]:_vm._e()]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }