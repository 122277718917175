


















































// Import vendors ----------------------------------------------------------------------------------
import { defineComponent } from '@vue/composition-api';
// Import utils ------------------------------------------------------------------------------------
import { useRoute } from '@/utils/router.utils';
// Import components -------------------------------------------------------------------------------
import CompositeSimpleCard from '@/components/composite/CompositeSimpleCard.vue';
import SettingsWorkspaceGeneralForm from '@/components/settings/SettingsWorkspaceGeneralForm.vue';
import SettingsWorkspaceMembers from '@/components/settings/SettingsWorkspaceMembers.vue';
import SettingsWorkspaceFeatures from '@/components/settings/SettingsWorkspaceFeatures.vue';
import SettingsWorkspaceLogo from '@/components/settings/SettingsWorkspaceLogo.vue';
import SessionsList from '@/components/miscellaneous/SessionsList.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'SettingsWorkspaceGeneral',
  components: {
    CompositeSimpleCard,
    SettingsWorkspaceGeneralForm,
    SettingsWorkspaceMembers,
    SettingsWorkspaceFeatures,
    SettingsWorkspaceLogo
    // SessionsList
  },
  props: {
    workspacesService: {
      type: Object,
      required: true
    }
  },
  setup() {
    const { hash } = useRoute();

    return {
      hash
    };
  }
});
