








// Import vendors ----------------------------------------------------------------------------------
import { defineComponent } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import SettingsLanguage from '@/components/settings/SettingsLanguage.vue';
import SettingsNotifications from '@/components/settings/SettingsNotifications.vue';
import SettingsUnits from '@/components/settings/SettingsUnits.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'SettingsPreferences',
  components: { SettingsLanguage, SettingsNotifications, SettingsUnits }
});
