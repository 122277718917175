




















// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent } from '@vue/composition-api';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'CompositeCard',
  props: {
    title: {
      type: String,
      default: undefined
    },
    icon: {
      type: String,
      default: undefined
    }
  },
  setup(_, { slots }) {
    const hasTop = computed(() => {
      return Boolean(slots.top);
    });

    const hasActions = computed(() => {
      return Boolean(slots.actions);
    });

    return {
      // Flags
      hasTop,
      hasActions
    };
  }
});
