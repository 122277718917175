import { useLocalStorage } from '@vueuse/core';

import type { Ref } from '@vue/composition-api';

const patientSidebarMiniVariant = useLocalStorage('patientSidebarMiniVariant', false);

export function useSidebar(): {
  toggleMiniVariant(): void;
  patientSidebarMiniVariant: Ref<boolean>;
} {
  function toggleMiniVariant() {
    patientSidebarMiniVariant.value = !patientSidebarMiniVariant.value;
  }

  return {
    patientSidebarMiniVariant,
    toggleMiniVariant
  };
}
