
































// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, ref, toRefs } from '@vue/composition-api';
// Import types ------------------------------------------------------------------------------------
import type { PropType } from '@vue/composition-api';
import type { FormFieldFile } from '@/utils/forms.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'FormFieldFile',
  inheritAttrs: false,
  props: {
    field: {
      type: Object as PropType<FormFieldFile>,
      required: true
    }
  },
  setup(properties, { slots }) {
    // Use this property to have typing in DOM
    const { field } = toRefs(properties);

    const hasLabelSlot = computed(() => {
      return Boolean(slots.label);
    });

    const hasProgressSlot = computed(() => {
      return Boolean(slots.progress);
    });

    const hasAppendSlot = computed(() => {
      return Boolean(slots.append);
    });

    function setValue(file: File | undefined) {
      field.value.value = file;
    }

    return {
      // Values
      f: field as any as FormFieldFile,
      // Flags
      hasLabelSlot,
      hasProgressSlot,
      hasAppendSlot,
      // Methods
      setValue
    };
  }
});
