














// Import vendors ----------------------------------------------------------------------------------
import { defineComponent } from '@vue/composition-api';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'CompositeSimpleCard',
  props: {
    title: {
      type: String,
      default: undefined
    },
    subtitle: {
      type: String,
      default: undefined
    },
    background: {
      type: String,
      default: 'white'
    },
    onClick: {
      type: Function,
      required: false
    }
  }
});
