











































// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, nextTick, onMounted, ref, watch } from '@vue/composition-api';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModule } from '@/plugins/podocore';
import i18n from '@/plugins/i18n';
// Import configurations ---------------------------------------------------------------------------
import { apiConfig } from '@/config/api.config';
// Import components -------------------------------------------------------------------------------
import CompositeDialog from '@/components/composite/CompositeDialog.vue';
import AlertError from '@/components/alerts/AlertError.vue';
import AlertSuccess from '@/components/alerts/AlertSuccess.vue';
import WorkspaceInviteForm from '@/components/workspace/WorkspaceInviteForm.vue';
// Import types ------------------------------------------------------------------------------------
import type { DialogId } from '@/plugins/podocore/modules/bus/bus.module';
import type { PropType } from '@vue/composition-api';
import type { WorkspaceEntity } from '@/plugins/podocore/repositories/workspaces.repository';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'DialogWorkspaceMemberInvite',
  props: {
    workspace: {
      type: Object as PropType<WorkspaceEntity>,
      required: true
    }
  },
  components: {
    CompositeDialog,
    AlertError,
    AlertSuccess,
    WorkspaceInviteForm
  },
  setup(properties) {
    const dialogId: DialogId = 'workspace-invite';
    const rDialog = ref<any>(null);

    const requestModule = usePodocoreModule('request');
    const busModule = usePodocoreModule('bus');

    const isSended = ref(false);

    const workspaceInviteMembersRequest = requestModule.useAuthenticatedRequest(
      `${apiConfig.default}/workspaces/${properties.workspace.cuid}/members/invite`,
      {
        axios: {
          method: 'POST'
        }
      }
    );

    onMounted(() => {
      watch(
        () => rDialog.value.displayContent,
        (value: boolean) => {
          if (!value) {
            workspaceInviteMembersRequest.cancel();

            nextTick(() => {
              workspaceInviteMembersRequest.clear();
            });
          }
        }
      );
    });

    function submitForm() {
      rDialog.value.$children[0].$children[0].$children[0].$children[1].submit();
    }

    function inviteWorkspaceMembers(data: any) {
      const redirect_uri = new URL(window.location.origin);

      redirect_uri.pathname = '/join-workspace';

      workspaceInviteMembersRequest.request({
        axios: {
          method: 'POST',
          data: {
            usersEmails: [data.email.toLowerCase()],
            redirectUrl: redirect_uri.href
          }
        }
      });

      isSended.value = true;
    }

    function closeDialog() {
      rDialog.value.close();
    }

    const isDisabled = computed(() => {
      return workspaceInviteMembersRequest.isPending.value;
    });

    const isSuccess = computed(() => {
      return !workspaceInviteMembersRequest.error.value && !!workspaceInviteMembersRequest.data.value;
    });

    watch(isSuccess, (value) => {
      if (value && isSended.value) {
        busModule.publish(
          busModule.events.workspaceMembersInvited({
            workspaceCuid: properties.workspace.cuid
          })
        );
        // Notification
        busModule.publish(
          busModule.events.createNotification({
            message: {
              body: {
                message: {
                  messageType: 'returned-invitation',
                  data: {
                    icon: 'check-circle',
                    custom: i18n.t('workspace.invite-new-member.sent')
                  }
                }
              },
              type: 'returned-invitation'
            }
          })
        );
        isSended.value = false;
        closeDialog();
      }
    });

    return {
      // References
      rDialog,
      // Values
      dialogId,
      workspaceInviteMembersRequest,
      // Methods
      submitForm,
      inviteWorkspaceMembers,
      closeDialog,
      // Flags
      isDisabled,
      isSuccess
    };
  }
});
