















































































































// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed, watch, ref } from '@vue/composition-api';
// Import utils ------------------------------------------------------------------------------------
import { useFormFieldFile, useFormObserver, useForm } from '@/utils/forms.utils';
import { useAnalytics } from '@/utils/analytics.utils';
// Import components -------------------------------------------------------------------------------
import CompositeSimpleCard from '@/components/composite/CompositeSimpleCard.vue';
import AlertError from '@/components/alerts/AlertError.vue';
import FormFieldFile from '@/components/forms/FormFieldFile.vue';
import { VBtn, VProgressCircular } from 'vuetify/lib';
// -----------------------------------------------------------------------------

export default defineComponent({
  name: 'SettingsWorkspaceLogo',
  components: {
    CompositeSimpleCard,
    FormFieldFile,
    AlertError,
    VBtn,
    VProgressCircular
  },
  props: {
    workspacesService: {
      type: Object,
      required: true
    }
  },
  setup(properties) {
    const rFileInput = ref(null);
    const rObserver = useFormObserver();

    const currentWorkspace = computed(() => {
      return (properties.workspacesService.state.value as any).context.current;
    });

    const { trackFailure } = useAnalytics();

    const isEditing = ref(true);

    const file = useFormFieldFile<File | undefined>({
      key: 'file',
      value: undefined,
      rules: {
        maxFileSize: 5000000,
        fileTypes: ['image/png', 'image/jpeg']
      }
    });

    // Form manager
    const { submit } = useForm({ file }, rObserver, (fields) => {
      if (!fields.file.value) throw new Error('file is missing');

      properties.workspacesService.send({
        type: 'PATCH_CURRENT_LOGO',
        data: {
          file: fields.file.value
        }
      });
      isEditing.value = false;
    });

    function cancel() {
      isEditing.value = false;
      file.value = undefined;
    }

    function clickOnInput() {
      if (rFileInput.value) (rFileInput.value as any).$children[0].$children[0].$refs.input.click();
    }

    function deleteLogo() {
      file.value = undefined;
      properties.workspacesService.send({
        type: 'DELETE_CURRENT_LOGO'
      });
    }

    watch(file, (_file) => {
      if (_file.value && !currentWorkspace.value._logoS3signedUrl) {
        isEditing.value = true;
      }
    });

    watch(isEditing, (value) => {
      if (value && currentWorkspace.value._logoS3signedUrl) {
        clickOnInput();
      }
    });

    const isErrored = computed(
      () =>
        [{ patchingCurrentLogo: 'failure' }].some((element) =>
          properties.workspacesService.state.value.matches(element)
        ) ||
        [{ deletingCurrentLogo: 'failure' }].some((element) =>
          properties.workspacesService.state.value.matches(element)
        )
    );

    watch(isErrored, (value) => {
      if (value) trackFailure('Settings/Workspace/Logo', "Workspace's logo save failed");
    });

    const isDisabled = computed(
      () =>
        !['success', { patchingCurrentLogo: 'failure' }, { patchingCurrentLogo: 'success' }].some((element) =>
          properties.workspacesService.state.value.matches(element)
        )
    );

    const isLoading = computed(
      () =>
        [{ patchingCurrentLogo: 'processing' }].some((element) =>
          properties.workspacesService.state.value.matches(element)
        ) ||
        [{ deletingCurrentLogo: 'processing' }].some((element) =>
          properties.workspacesService.state.value.matches(element)
        )
    );

    watch(
      currentWorkspace,
      () => {
        if (currentWorkspace.value._logoS3signedUrl && !isLoading.value && !isErrored.value) {
          isEditing.value = false;
        }
      },
      {
        deep: true,
        immediate: true
      }
    );

    return {
      // Form observer
      rObserver,
      // Form fields
      file,
      // Values
      currentWorkspace,
      // Actions
      submit,
      cancel,
      clickOnInput,
      deleteLogo,
      // Flags
      isEditing,
      isErrored,
      isDisabled,
      isLoading,
      // Ref
      rFileInput
    };
  }
});
